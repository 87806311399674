<div class="HeaderSupportClass">
  <div class="header-docs font-icons icon-guide_header" (click)="showHelp()"></div>
  <div class="header-support font-icons icon-support_header"
    [class.opened]="showSupportBalloon"
    (click)="toggleSupportBalloon($event, !supBalEnableClickOutside)"
    (clickOutside)="toggleSupportBalloon($event, !supBalEnableClickOutside)"
    [clickOutsideEnabled]="supBalEnableClickOutside">
    @if (showSupportBalloon) {
      <ng-container [ngTemplateOutlet]="supportBalloon"></ng-container>
    }
  </div>
  <div class="header-user font-icons icon-user_logo"
    [class.opened]="showUserBalloon"
    (click)="toggleUserBalloon($event, !userBalEnableClickOutside)"
    (clickOutside)="toggleUserBalloon($event, !userBalEnableClickOutside)"
    [clickOutsideEnabled]="userBalEnableClickOutside">
    @if (showUserBalloon) {
      <ng-container [ngTemplateOutlet]="userBalloon"></ng-container>
    }
  </div>
</div>

<ng-template #supportBalloon>
  <div class="header-support-balloon" (click)="openSupportBalloon($event)">
    <div class="header-support-balloon-title">{{'GENERAL.SUPPORT' | translate}}</div>
    <div class="header-support-balloon-item" (click)="openSupportBalloon($event)">
      <span class="font-icons icon-phone"></span>
      <span class="text">{{supportPhone}}</span>
    </div>
    <div class="header-support-balloon-item" (click)="openSupportBalloon($event)">
      <span class="font-icons icon-mail"></span>
      <a href="mailTo:{{supportEmail}}" target="_blank"
      class="outer-href">{{supportEmail}}</a>
    </div>
  </div>
</ng-template>
<ng-template #userBalloon>
  <div class="header-user-balloon" (click)="openUserBalloon($event)">
    <div class="header-user-balloon-item">
      <a class="inner-href" (click)="changePassword()">{{'GENERAL.CHANGE_PASSWORD' | translate}}</a>
    </div>
    <div class="header-user-balloon-item">
      <a class="inner-href" (click)="exit()">{{'GENERAL.EXIT' | translate}}</a>
    </div>
  </div>
</ng-template>
