import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, Validators} from "@angular/forms";
import {PasswordValidator} from "@shared/validators/password.validator";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {PasswordApiService} from "@core/services/api/password-api.service";
import {ToastrService} from "ngx-toastr";
import {IValidator} from "@core/interfaces/validator";
import {UsdService} from "@core/services/usd.service";
import { ButtonComponent, EButtonStyle } from 'ngx-strong-frontend-lib/components/button';
import { PasswordInputComponent } from '../../password-input/password-input.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    PasswordInputComponent,
    NgbTooltip,
    ButtonComponent,
  ],
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent {

  @Input() forModal: boolean = true;
  @Output() OnCancel: EventEmitter<any> = new EventEmitter<any>();

  public form: FormGroup = new FormGroup({
    currentPassword: new FormControl('', [Validators.required, Validators.minLength(8), PasswordValidator()]),
    newPassword: new FormControl('', [Validators.required, Validators.minLength(8), PasswordValidator()]),
    newPasswordAgain: new FormControl('', [Validators.required, Validators.minLength(8), PasswordValidator()])
  });

  public isCurrentPasswordError: boolean = false;
  public currentPasswordResult: string = null;
  public isNewPasswordError: boolean = false;
  public newPasswordResult: string = null;
  public isNewPasswordAgainError: boolean = false;
  public newPasswordAgainResult: string = null;
  public newPasswordTooltip: string[] = [];

  private successTitles: string[] = [];
  private errorTitles: string[] = [];
  private requirements: string[] = [];
  private errorRequest: string = '';
  public btnTitleForModal: string = '';
  public btnTitle: string = '';

  readonly EButtonStyle = EButtonStyle;

  constructor(
    private translateService: TranslateService,
    private passwordApiService: PasswordApiService,
    private toastr: ToastrService,
    private userService: UsdService
  ) {
    this.translateService.get(['PASSWORD', 'GENERAL']).subscribe((result: string[]) => {
      this.successTitles = result['PASSWORD']['SUCCESS'];
      this.errorTitles = result['PASSWORD']['ERRORS'];
      this.requirements = result['PASSWORD']['REQUIREMENT'];
      this.errorRequest = result['GENERAL']['ERROR_REQUEST'];
      this.btnTitleForModal = result['GENERAL']['CHANGE'];
      this.btnTitle = result['GENERAL']['CONFIRM'];
      for (const key in this.requirements) {
        if (this.requirements.hasOwnProperty(key)) {
          this.newPasswordTooltip.push(this.requirements[key]);
        }
      }
    });
  }

  public cancel() {
    this.clearAndClose();
  }

  public changePassword() {
    const current_password = this.form.get('currentPassword').value;
    const new_password = this.form.get('newPassword').value;
    const new_password_again = this.form.get('newPasswordAgain').value;
    if (this.form.valid
      && new_password_again === new_password
      && current_password !== new_password) {
      this.passwordApiService.changePassword(new_password, current_password).subscribe({
        next: (result) => {
          this.clearAndClose();
          this.toastr.success(this.successTitles['CHANGE']);
          this.userService.logout();
        },
        error: error => {
          if (error.error && error.error['message']) {
            this.toastr.error(error.error['message'], this.errorTitles['CHANGE']);
          } else {
            this.toastr.error(this.errorTitles['CHANGE']);
          }
        }
      });
    } else {
      this.checkCurrentPassword();
      this.checkNewPassword();
      this.checkNewPasswordAgain();
    }
  }

  private clearAndClose() {
    this.form.reset();
    this.isCurrentPasswordError = false;
    this.currentPasswordResult = null;
    this.isNewPasswordError = false;
    this.newPasswordResult = null;
    this.isNewPasswordAgainError = false;
    this.newPasswordAgainResult = null;
    if (this.forModal) {
      this.OnCancel.emit();
    }
  }

  public checkCurrentPassword() {
    const current_password = this.form.get('currentPassword').value;
    const errors = this.form.get('currentPassword').errors;
    if (errors && errors['required']) {
      this.isCurrentPasswordError = true;
      this.currentPasswordResult = this.errorTitles['REQUIRED'];
    } else if (errors && ((errors['password'] && errors['password'].length > 0) || errors['minlength'])) {
      this.isCurrentPasswordError = true;
      this.currentPasswordResult = this.errorTitles['NO_CORRECT'];
    } else {
      this.isCurrentPasswordError = false;
      this.currentPasswordResult = null;
    }
  }

  public checkNewPassword() {
    const new_password = this.form.get('newPassword').value;
    const current_password = this.form.get('currentPassword').value;
    const errors = this.form.get('newPassword').errors;
    if (errors && errors['required']) {
      this.isNewPasswordError = true;
      this.newPasswordResult = this.errorTitles['REQUIRED'];
    } else if (errors && ((errors['password'] && errors['password'].length > 0) || errors['minlength'])) {
      this.isNewPasswordError = true;
      this.newPasswordResult = this.errorTitles['NO_CORRECT'];
    } else if (current_password === new_password) {
      this.isNewPasswordError = true;
      this.newPasswordResult = this.errorTitles['NO_VALID_TO_CURRENT'];
    } else {
      this.isNewPasswordError = false;
      this.newPasswordResult = this.successTitles['CORRECT'];
    }
    this.prepareTooltip(errors);
  }

  public checkNewPasswordAgain() {
    const new_password = this.form.get('newPassword').value;
    const new_password_again = this.form.get('newPasswordAgain').value;
    const errors = this.form.get('newPasswordAgain').errors;
    if (errors && errors['required']) {
      this.isNewPasswordAgainError = true;
      this.newPasswordAgainResult = this.errorTitles['REQUIRED'];
    } else if (errors && ((errors['password'] && errors['password'].length > 0) || errors['minlength'])) {
      this.isNewPasswordAgainError = true;
      this.newPasswordAgainResult = this.errorTitles['NO_CORRECT'];
    } else if (new_password_again !== new_password) {
      this.isNewPasswordAgainError = true;
      this.newPasswordAgainResult = this.errorTitles['NO_CHECK'];
    } else {
      this.isNewPasswordAgainError = false;
      this.newPasswordAgainResult = this.successTitles['CHECK'];
    }
  }

  private prepareTooltip(errors: ValidationErrors) {
    if (!errors) {
      return;
    }
    this.newPasswordTooltip = [];
    if (errors['minlength']) {
      this.newPasswordTooltip.push(this.requirements['LENGTH']);
    }
    if (errors['password'] && errors['password'].length > 0) {
      const passwordErrors = errors['password'];
      passwordErrors.forEach((error: IValidator) => {
        if (error['password-rus']) {
          this.newPasswordTooltip.push(this.requirements['NO_RUS']);
        }
        if (error['password-AZ']) {
          this.newPasswordTooltip.push(this.requirements['CAPITAL']);
        }
        if (error['password-az']) {
          this.newPasswordTooltip.push(this.requirements['SMALL']);
        }
        if (error['password-num']) {
          this.newPasswordTooltip.push(this.requirements['NUMBER']);
        }
        if (error['password-spec']) {
          this.newPasswordTooltip.push(this.requirements['SPEC']);
        }
      });
    }
  }

}
