import {Component, OnDestroy, OnInit} from "@angular/core";
import {IUsdInfo} from "@core/interfaces/usd";
import {Router} from "@angular/router";
import {MenuService} from "ngx-strong-frontend-lib/services/menu";
import {UsdService} from "@core/services/usd.service";
import {DateTimeServiceLocal} from "@shared/services/date-time.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  standalone: true,
  imports: [
    TranslateModule,
  ],
  selector: 'app-header-usd',
  templateUrl: './header-usd.component.html',
  styleUrls: ['./header-usd.component.scss']
})
export class HeaderUsdComponent implements OnInit, OnDestroy {

  public usd: IUsdInfo;
  public data: string = null;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private menuService: MenuService,
    private usdService: UsdService,
    private dateTimeService: DateTimeServiceLocal
  ) {
  }

  ngOnInit() {
    this.usdService.getUsdInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((info: IUsdInfo) => {
        this.usd = info;
        this.data = this.usd && this.usd.currentTime
          ? this.dateTimeService.getDateFromBackend(this.usd.currentTime, this.dateTimeService.getDateFormat('dateTimeWithSecond'))
          : null;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public refreshPage() {
    document.location.href = document.location.href;
  }
}
