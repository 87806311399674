import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoAccessComponent } from 'ngx-strong-frontend-lib/components/no-access';
import { PageNotFoundComponent } from 'ngx-strong-frontend-lib/components/page-not-found';
import { AuthGuard } from '@core/guards/auth-guard.service';
import { QuicklinkStrategy } from 'ngx-quicklink';

const routes: Routes = [
  { path: '', redirectTo: 'sensors', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'sensors',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/sensors/sensors.module').then((m) => m.SensorsModule),
  },

  {
    path: 'devices',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/devices/devices.module').then((m) => m.DevicesModule),
  },
  {
    path: 'info',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/info/info.module').then((m) => m.InfoModule),
  },
  {
    path: 'history',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@modules/history/history.module').then((m) => m.HistoryModule),
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@modules/settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'help',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@modules/help/help.module').then((m) => m.HelpModule),
  },
  {
    path: 'diagnostic',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@modules/diagnostic/diagnostic.module').then(
        (m) => m.DiagnosticModule,
      ),
  },
  { path: 'no-access', component: NoAccessComponent },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: 'page-not-found' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: QuicklinkStrategy }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
