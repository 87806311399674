import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SystemParamsService } from '@core/services/system-params.service';
import { SYSTEM_PARAMETER_NAME } from '@app/app.enums';
import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import quarter from 'dayjs/plugin/quarterOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import dayjsFormats from 'dayjs/plugin/customParseFormat';
import { version } from './app-info';
dayjs.extend(quarter);
dayjs.extend(dayjsUtc);
dayjs.extend(dayjsFormats);
dayjs.extend(isSameOrBefore);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  private appShortTitle: string = null;
  private appFullTitle: string = null;
  private pageTitle: string = null;
  public hideHeader: boolean = true;

  private publicSystemParamsSubscription: Subscription = new Subscription();

  @HostBinding('attr.app-version')
  public readonly appVersion = version;

  constructor(
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private systemParamsService: SystemParamsService,
  ) {
    this.publicSystemParamsSubscription = this.systemParamsService
      .getPublicSystemParams()
      .subscribe((systemParams: any) => {
        this.appShortTitle =
          systemParams && systemParams[SYSTEM_PARAMETER_NAME.PRODUCT_SHORT_NAME]
            ? systemParams[SYSTEM_PARAMETER_NAME.PRODUCT_SHORT_NAME]
            : null;
        this.appFullTitle =
          systemParams && systemParams[SYSTEM_PARAMETER_NAME.PRODUCT_FULL_NAME]
            ? systemParams[SYSTEM_PARAMETER_NAME.PRODUCT_FULL_NAME]
            : null;
        this.setPageTitle();
      });
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return null;
        }),
      )
      .subscribe((ttl: string) => {
        this.hideHeader =
          this.router.url.endsWith('page-not-found') ||
          this.router.url.indexOf('auth') > -1;
        this.pageTitle = ttl;
        this.setPageTitle();
      });
  }

  ngOnDestroy() {
    this.publicSystemParamsSubscription.unsubscribe();
  }

  private setPageTitle() {
    let title: string = null;
    if (this.pageTitle) {
      title = this.appShortTitle
        ? `${this.appShortTitle}. ${this.pageTitle}`
        : this.pageTitle;
    } else {
      title = this.appFullTitle ? this.appFullTitle : null;
    }
    this.titleService.setTitle(title);
  }
}
