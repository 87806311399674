import { Component } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ChangePasswordFormComponent } from './change-password-form/change-password-form.component';

@Component({
  standalone: true,
  imports: [
    ChangePasswordFormComponent,
  ],
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent {

  constructor(
    public activeModal: NgbActiveModal
  ) {}

  public onCancel () {
    this.activeModal.close();
  }
}
