<div class="passwordInputClass">
  <div class="password-input-block" [style.height.px]="inputHeight">
    <input id="{{inputId}}"
      #input
      class="password-input"
      maxlength="255"
      [style.height.px]="inputHeight"
      [class.error]="hasError"
      [class.success]="hasSuccess"
      name="{{inputId}}"
      [(ngModel)]="model"
      [type]="type"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [autocomplete]="'off'"
      (focus)="emitFocusEvent()"
      (blur)="emitBlurEvent()"
      >
    @if (withShowIcon && !!model) {
      <div class="password-input-show">
        <span class="font-icons"
          [class.icon-show]="showPassword"
          [class.icon-hide]="!showPassword"
          (click)="changeVisiblePassword()"
        ></span>
      </div>
    }
  </div>
  @if (resultText) {
    <div
      class="password-input-result"
      [class.error]="hasError"
      [class.success]="hasSuccess"
    >{{resultText}}</div>
  }
</div>
