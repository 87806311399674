<div class="changePasswordFromClass">
  <div class="modal-header">
    <span class="font-icons icon-gerb_ministerstva_oborony">
      <span class="path1"></span><span class="path2"></span><span class="path3"></span>
      <span class="path4"></span><span class="path5"></span><span class="path6"></span>
      <span class="path7"></span>
    </span>
    <div class="title">{{'PASSWORD.CHANGE_PASSWORD' | translate}}</div>
  </div>
  <div class="modal-body" [class.for-modal]="forModal">
    <form [formGroup]="form" class="change-password-form">
      <div class="form-field">
        <div class="form-field-label">
          <span>{{'PASSWORD.CURRENT_PASSWORD' | translate}}</span>
        </div>
        <app-password-input class="form-field-input"
          formControlName="currentPassword"
          inputId="currentPassword"
          [hasError]="isCurrentPasswordError"
          [hasSuccess]="!isCurrentPasswordError && !!currentPasswordResult"
          [resultText]="currentPasswordResult"
          (OnChange)="checkCurrentPassword()"
        ></app-password-input>
      </div>
      <div class="form-field">
        <div class="form-field-label">
          <span>{{'PASSWORD.NEW_PASSWORD' | translate}}</span>
          @if (newPasswordTooltip && newPasswordTooltip.length > 0) {
            <span
              class="font-icons icon-info"
              [ngbTooltip]="passwordTooltip"
              placement="top"
              container="body"
            ></span>
          }
        </div>
        <app-password-input class="form-field-input"
          formControlName="newPassword"
          inputId="newPassword"
          [hasError]="isNewPasswordError"
          [hasSuccess]="!isNewPasswordError && !!newPasswordResult"
          [resultText]="newPasswordResult"
          (OnChange)="checkNewPassword()"
        ></app-password-input>
      </div>
      <div class="form-field">
        <div class="form-field-label">
          <span>{{'PASSWORD.NEW_PASSWORD_AGAIN' | translate}}</span>
        </div>
        <app-password-input class="form-field-input"
          formControlName="newPasswordAgain"
          inputId="newPasswordAgain"
          [hasError]="isNewPasswordAgainError"
          [hasSuccess]="!isNewPasswordAgainError && !!newPasswordAgainResult"
          [resultText]="newPasswordAgainResult"
          (OnChange)="checkNewPasswordAgain()"
        ></app-password-input>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    @if (forModal) {
      <lib-button
        class="modal-footer-btn"
        (click)="cancel()"
        [title]="'GENERAL.CANCEL' | translate"
      ></lib-button>
    }
    <lib-button class="modal-footer-btn blue"
      (click)="changePassword()"
      [buttonStyles]="[EButtonStyle.FILLED]"
      [title]="forModal ? btnTitleForModal : btnTitle"
    ></lib-button>
  </div>
</div>
<ng-template #passwordTooltip>
  @for (tooltip of newPasswordTooltip; track tooltip) {
    <p>{{tooltip}}</p>
  }
</ng-template>
